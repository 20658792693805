!(function (e) {
  e.FTCaptures ||
    (e.FTCaptures = new (function () {
      (this.routeID = null),
        (this.environment = null),
        (this.eventHandlers = {}),
        (this.tokenizationFrame = null),
        (this.iFrames = []),
        (this.iFramesMessages = 0);
      let a = {},
        r = this;
      (r.environmentDomain = null),
        (r.tags = null),
        (this.on = function (e, t) {
          this.eventHandlers[e] || (this.eventHandlers[e] = []),
            this.eventHandlers[e].push(t);
        }),
        (this.emit = function (e, t) {
          this.eventHandlers[e] &&
            this.eventHandlers[e].forEach(function (e) {
              e(t);
            });
        }),
        (this.init = function (e, t, a, i) {
          switch (t) {
            case "staging":
              r.environmentDomain = "firstoken-staging.co";
              break;
            case "production":
              r.environmentDomain = "firstoken.co";
              break;
            default:
              throw new Error(
                "Invalid environment syntax. Permitted values are staging or production."
              );
          }
          var n;
          (this.routeID = e), (this.environment = t), (this.iFrames = []);
          for (n of document.querySelectorAll("iframe"))
            n.getAttribute("data-route") === e && this.iFrames.push(n);
          return i && (r.tags = i), a({}), this;
        }),
        (this.field = function (e, t) {
          if (!this.routeID)
            throw new Error(
              "You must initialize the SDK before creating fields."
            );
          var a,
            i,
            n = document.querySelector(e);
          if (n)
            return (
              window.getComputedStyle(n).height,
              (n.innerHTML = ""),
              (a = JSON.stringify(t.css)),
              (a = encodeURIComponent(a)),
              (t = new URLSearchParams(t).toString()),
              ((i = document.createElement("iframe")).style.width = "100%"),
              (i.style.border = "none"),
              (i.src =
                `https://captures.${r.environmentDomain}/elements?${t}&css=` +
                a),
              i.setAttribute("data-route", this.routeID),
              n.appendChild(i),
              this
            );
          throw new Error(`The ${e} container could not be found.`);
        }),
        (this.tokenize = function (i) {
          return (
            (r.routeID = i),
            new Promise((e, t) => {
              (window.ajaxResolve = e),
                (window.ajaxReject = t),
                (this.iFrames = Array.from(
                  document.querySelectorAll(`iframe[data-route="${i}"]`)
                ));
              for (var a of this.iFrames)
                a.contentWindow.postMessage(
                  { action: "hostReady" },
                  "https://captures." + r.environmentDomain
                );
            })
          );
        }),
        (this.validate = function (a) {
          return new Promise((i, e) => {
            const n = {};
            let o = !1;
            this.iFrames = Array.from(
              document.querySelectorAll(`iframe[data-route="${a}"]`)
            );
            for (var t of this.iFrames)
              t.contentWindow.postMessage(
                { action: "validateField" },
                "https://captures." + r.environmentDomain
              );
            window.addEventListener("message", function (e) {
              var t, a;
              e.origin === "https://captures." + r.environmentDomain &&
                "validationResult" === e.data.action &&
                ((a = e.data.data.fieldName),
                (t = {
                  length: e.data.data.length,
                  isvalid: e.data.data.isValid,
                }),
                (n[a] = t),
                e.data.data.isValid || (o = !0),
                Object.keys(n).length === r.iFrames.length) &&
                ((a = { fields: n, hasErrors: o }), i(a));
            });
          });
        }),
        (this.destroy = function (t) {
          (this.iFrames = this.iFrames.filter(
            (e) =>
              e.getAttribute("data-route") !== t ||
              (e && e.parentNode && e.parentNode.removeChild(e), !1)
          )),
            activeInstances[t] &&
              (activeInstances[t].destroy(), delete activeInstances[t]);
        }),
        window.addEventListener("message", function (e) {
          var t;
          e.origin === "https://captures." + r.environmentDomain &&
          "dataReady" === e.data.action
            ? ((t = e.data.data),
              r.iFramesMessages++,
              (a[t.fieldName] = t.value),
              r.tags && 0 < r.tags.length && (a.tags = r.tags),
              r.iFramesMessages === r.iFrames.length &&
                (fetch(
                  `https://api.${r.environmentDomain}/routes/` + r.routeID,
                  {
                    method: "POST",
                    body: JSON.stringify(a),
                    headers: { "Content-Type": "application/json" },
                  }
                )
                  .then((e) => {
                    if (e.ok) return e.json();
                    throw new Error("Network error.");
                  })
                  .then((e) => {
                    window.ajaxResolve(e);
                  })
                  .catch((e) => {
                    "Network error" === e.message
                      ? window.ajaxReject("Network error")
                      : window.ajaxReject("Network error."),
                      window.ajaxReject("Network error.");
                  }),
                (a = {}),
                (r.iFramesMessages = 0)))
            : {
                cardNumberValidationSuccess: "cardNumberValidationSuccess",
                cardHolderValidationSuccess: "cardHolderValidationSuccess",
                cardExpirationDateValidationSuccess:
                  "cardExpirationDateValidationSuccess",
                cardSecurityCodeValidationSuccess:
                  "cardSecurityCodeValidationSuccess",
                expirationYearValidationSuccess:
                  "expirationYearValidationSuccess",
                expirationMonthValidationSuccess:
                  "expirationMonthValidationSuccess",
                textValidationSuccess: "textValidationSuccess",
                AmountValidationSuccess: "AmountValidationSuccess",
                selectValidationSuccess: "selectValidationSuccess",
                radioValidationSuccess: "radioValidationSuccess",
                cardNumberValidationFailed: "cardNumberValidationFailed",
                cardHolderValidationFailed: "cardHolderValidationFailed",
                cardExpirationDateValidationFailed:
                  "cardExpirationDateValidationFailed",
                cardSecurityCodeValidationFailed:
                  "cardSecurityCodeValidationFailed",
                expirationYearValidationFailed:
                  "expirationYearValidationFailed",
                expirationMonthValidationFailed:
                  "expirationMonthValidationFailed",
                textValidationFailed: "textValidationFailed",
                AmountValidationFailed: "AmountValidationFailed",
                selectValidationFailed: "selectValidationFailed",
                radioValidationFailed: "radioValidationFailed",
              }[(t = e.data.eventName)] && r.emit(t, e.data);
        });
    })());
})(window);
